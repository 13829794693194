import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
  Box,
  Text,
  useToast,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Spinner,
  Image, Heading
} from "@chakra-ui/react";
import { motion } from "framer-motion";

export const SuccessPage = () => {
    const { registrationID } = useParams();
    const [registrationData, setRegistrationData] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchRegistration = async () => {
          setLoading(true);
          try {
            const { data, error } = await supabase
              .from("full_registration_details_with_enrollment")
              .select(
                "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality,course_dates" //course_dates
              )
              .gte("start_date", new Date().toLocaleDateString('fr-CA'))
              .eq("id", registrationID)
              .single();
    
            if (error) throw error;
    
            setRegistrationData(data);
          } catch (error) {
            console.error("Error fetching registration:", error);
            toast({
              title: "Error",
              description: "Could not load registration details.",
              status: "error",
              isClosable: true,
              position: "top",
            });
          } finally {
            setLoading(false);
          }
        };
    
        fetchRegistration();
      }, [registrationID, toast]);
    return (
    loading ? (
    <Box
        ddisplay="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh" // Use minHeight instead of height
        color="black"
        textAlign="center"
        p={8}
        overflow="auto"
      >
        <Spinner size="xl" color="teal.500" />
      </Box>):(
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minheight="100vh"
      color="black"
      textAlign="center"
      p={8}
      overflow="auto" // For scrolling if content exceeds the viewport height
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <VStack spacing={8} align="center" w="full">
          <motion.div
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
          >
            <Image
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAMFBMVEVHcEwOFzUOFzUOFzUOFzUOFjUOFzUPGDYPFzYOFzUOFzUOFzUOFzUOFzUOFzUOFzV6hvApAAAAD3RSTlMARvPjmlTRDR2xcIUyYL/4uzujAAAAsElEQVQokZWSSRbEIAhEEWczcP/btmJsJeoitQv/QUEZgEnhCnPxkSUiu2FnZoQbiAVuWhMz0kuoKyQ1Oh0HT/IPI9eZ48IJoBqkaVbgO2Tn1Srx3xl9g7HPqp7Gd8d7MLKIh1jfNLa6LjSY+NMbvOdYeMMQ62pdqhxTvM57aVAWVG7rblsWMj1OAmmQEV0kNb5neDHxJ6gXHF8TQDLhmA/BPYMhYFQTy0pRa2eW6LN+wJAQWr/Uv44AAAAASUVORK5CYII="
              alt="Logo"
              boxSize="100px" 
              mb={4}
            />
          </motion.div>
          <motion.div
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
          >
            <Heading size={"3xl"} >
              🎉 Enrollment confirmed! 🎉
            </Heading>
          </motion.div>

          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.3, duration: 0.8, ease: "easeOut" }}
          >
            <Text fontSize="xl">
              Thank you for your payment, {registrationData.participant_name}! Your enrollment for this course is confirmed.
            </Text>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
          >
            <Text fontSize="xl">
              You will receive the signed student contract via your email <span style={{color: "blue"}}>{registrationData.participant_email}</span> soon.
            </Text>
            <Text fontSize="xl">
              See you for the course!
            </Text>
          </motion.div>

          {/* Course Details Section */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 1, ease: "easeOut" }}
          >
            <Box
              bg="gray.50"
              border="1px solid"
              borderColor="gray.200"
              borderRadius="md"
              p={6}
              boxShadow="sm"
              w="full"
              maxW="900px"
            >
              <Text fontSize="2xl" fontWeight="bold" mb={4}>
                {registrationData?.name}
              </Text>
              <Table variant="simple" size="md" width="100%">
                <Thead bg="gray.100">
                  <Tr>
                    <Th textAlign="center">Date</Th>
                    <Th textAlign="center">Start Time</Th>
                    <Th textAlign="center">End Time</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {registrationData?.course_dates.map((session, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">{session.date}</Td>
                      <Td textAlign="center">{session.start_time}</Td>
                      <Td textAlign="center">{session.end_time}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Text mt={4} textAlign="left">
                <b>Venue:</b> Habitat Learning Centre, 1 Goldhill Plaza, #02-27, Singapore 308899. The nearest MRT is Novena.
              </Text>
              <Text mt={2} textAlign="left">
                <b>Directions:</b>{" "}
                <a
                  href="https://sozcode.com/goldhill-plaza-how-to-get-there"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  https://sozcode.com/goldhill-plaza-how-to-get-there
                </a>
              </Text>
              <Text mt={4} textAlign="left">
                <b>Duration:</b> 3 hours per session, except on the last day, which includes a 45-minute assessment.
              </Text>
            </Box>
          </motion.div>
        </VStack>
      </motion.div>
    </Box>)
  );
};

export default SuccessPage;