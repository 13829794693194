import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import { useAuth } from "./providers/AuthProvider";
import {
    Box,
    Button,
    Heading,
    Stack,
    Text,
    Card,
    HStack,
    Badge,
    Divider,
} from "@chakra-ui/react";

export const AssessmentForCourses = () => {
    const {auth, isLoading} = useAuth();
    const {user} = useAuth();
    console.log("Assessment user:",user.id)
    const [groupedCourses, setGroupedCourses] = useState([]);

    const getAllEnrolledCourses = async () => {
        const { data, error } = await supabase
            .from("full_assessment_details")
            .select("*")
            //.eq("id", "d894dce8-b79e-479e-86df-cf7eaa4f4991");
            .eq("user_id", user.id); 

        if (data) {
            console.log("Original Data:", data);

            // Group data by course name
            const grouped = data.reduce((acc, item) => {
                const courseName = item.name;

                if (!acc[courseName]) {
                    acc[courseName] = {
                        name: courseName,
                        start_date: item.start_date,
                        end_date: item.end_date,
                        assessments: [],
                    };
                }
                acc[courseName].assessments.push({
                    assessment_name: item.assessment_name,
                    deadline:  item.assessment_deadline
                    ? item.assessment_deadline.split("T")[0] // Extract date if it's not null
                    : "No Deadline", // Fallback if assessment_deadline is null,
                    submitted_on: item.submitted_on,
                    id: item.id,
                    assessment_id:item.assessment_id
                });
                return acc;
            }, {});

            setGroupedCourses(Object.values(grouped));
        }

        if (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getAllEnrolledCourses();
    }, []);

    return (
        <Box>
            <Heading size="md" mb={5}>
                Assessments For Your Enrolled Courses
            </Heading>
            {groupedCourses.map((course) => (
                <Card key={course.name} maxW={"600px"} p={5} mb={5}>
                    <Box>
                        <Heading size="sm" mb={2}>
                            {course.name}
                        </Heading>
                        <HStack mb={2}>
                            <Text w={200} color="gray.500" fontWeight={300}>
                                Start Date
                            </Text>
                            <Text>{course.start_date}</Text>
                        </HStack>
                        <HStack mb={2}>
                            <Text w={200} color="gray.500" fontWeight={300}>
                                End Date
                            </Text>
                            <Text>{course.end_date}</Text>
                        </HStack>
                        <Divider mt={4} mb={4} />

                        <Stack spacing={3}>
                            {course.assessments.map((assessment, index) => (
                                <Box key={index}>
                                    <Badge mb={1} colorScheme={assessment.submitted_on ? "green" : "red"}>
                                        {assessment.submitted_on ? "Submitted" : "Not Complete"}
                                    </Badge>
                                    <Text fontWeight="medium">{assessment.assessment_name}</Text>
                                    <HStack mt={1}>
                                        <Text color="gray.500" fontSize="sm">
                                            Deadline: {assessment.deadline}
                                        </Text>
                                    </HStack>
                                    <Link to={`/assessment/${assessment.assessment_id}`}>
                                        <Button variant={"link"} mt={2}>
                                            {assessment.submitted_on ? "View submission" : "Start submission"}
                                        </Button>
                                    </Link>
                                    {index !== course.assessments.length - 1 && <Divider mt={3} />}
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                </Card>
            ))}
        </Box>
    );
};