/* eslint-disable */

import logo from "./logo.svg";
import "./App.css";
import {Routes, Route, Outlet, Link, Navigate, useParams, useLocation} from "react-router-dom";
import React, { useState, useEffect } from "react";
import AuthProvider from "./providers/AuthProvider";
import {useAuth} from "./providers/AuthProvider";
import LoginPage from "./LoginPage";
import {Registration}from "./Registration"
import {EnrolledCourses} from "./EnrolledCourses";
import {EachCourse} from "./EachCourse";
import SignaturePad from 'react-signature-canvas'
import { useNavigate } from "react-router-dom"; 
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Container,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuList,
    MenuItem,
    Menu,
} from "@chakra-ui/react";
import { FiBell, FiSearch } from "react-icons/fi";
import {
    Center,
    FormControl,
    Heading,
    Image,
    Select,
    Stack,
    Text,
    FormLabel,
    Spinner, Badge, Alert, AlertIcon
} from "@chakra-ui/react";
import {supabase} from "./utils/supabaseClient";
import {CheckCircleIcon} from "@chakra-ui/icons";
import {Attendance} from "./Attendance";
import {AssessmentForCourses} from "./AssessmentForCourses";
import {EachAssessment} from "./EachAssessment";
import { TestLMS } from "./TestLMS";
import {Enrollment} from "./Enrollment";
import {SuccessPage} from "./SuccessPage";
import {SuccessRegistration} from "./SuccessRegistration";
import {SuccessAcknowledgement} from "./SuccessAcknowledgement";
import { AttendanceSelection } from "./AttendanceSelection";

const Home = () => {
    return (
        <Heading>Test</Heading>
    )
}

const Courses = () => {
    return (
        "Courses"
    )
}

const ProtectedRoute = ({
                            redirectPath = '/login',
                            children,
                        }) => {
    const {auth, isLoading} = useAuth()
    console.log(auth)
    if (auth && !isLoading) {
        return children;
    } else if (!isLoading) {
        return <Navigate to={redirectPath} replace/>;
    }

};


const Acknowledgement = () => {
    const [registration, setRegistration] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const { registrationId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const payment_stage = params.get("session_id")
    const getRegistration = async () => {
        const { data, error } = await supabase
            .from("registration")
            .select("participant_name, id, participant_email, registration_stage, course_run_details!inner(*)")
            .eq("id", registrationId)
            .single()

        if (data) {
            console.log(data);
            setRegistration(data);
        }
        if (error) {
            console.log(error);
        }
    };

    const handleCheckout = async () => {
        setSubmitting(true)
        try {
            const response = await fetch('https://eovhmnu1acpextc.m.pipedream.net', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    product_id: registration.course_run_details.course_type_code,
                    registration_id: registrationId,
                    participant_email: registration.participant_email
                }),
            });

            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
                setSubmitting(false);
            } else {
                console.error('No URL found in response');
            }
        } catch (error) {
            console.error('Error during the checkout process:', error);
        }
    };

    useEffect(() => {
        getRegistration()
    }, [])

    return (
        registration === null
            ?
            <Spinner/>
            :
            <Box w={"100%"} display="flex" justifyContent="center" alignItems="center" bg={"#F4EEEA"} m={"auto"}>
            <Center maxW={"90%"} width={800} pt={10} pb={20}>

                <Stack spacing="4" width="100%">
                    <Heading size={"sm"} mb={2}>{registration.registration_stage === "confirmed" || payment_stage !== "failed" ? "Course Confirmation" : "Registration Details - Pending Payment"}</Heading>
                    <Alert status='warning' mb={5}>
                        <AlertIcon />
                        {registration.registration_stage === "confirmed" || payment_stage !== "failed" ? `You will receive a course confirmation email sent to ${registration.participant_email} shortly. Please reach out to courses@sozcode.com if you did not receive the email.` : "This is not a confirmation of your registration. Please click on the checkout button below to complete your checkout."}
                    </Alert>
                    {
                        registration.registration_stage === "confirmed" || payment_stage !== "failed"
                            ? null
                            : <Button onClick={handleCheckout} isLoading={submitting}>Proceed to checkout</Button>
                    }

                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Registration Stage
                        </Text>
                        <Box>
                            <Badge colorScheme={registration.registration_stage === "confirmed" || payment_stage !== "failed" ? 'green' : 'red'}>{registration.registration_stage === "confirmed" || payment_stage !== "failed" ? "Payment Received" : "Pending Payment"}</Badge>
                        </Box>
                    </HStack>
                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Participant Name
                        </Text>
                        <Text fontWeight={600}>{registration.participant_name}</Text>
                    </HStack>
                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Course Title
                        </Text>
                        <Text fontWeight={600}>{registration.course_run_details.name}</Text>
                    </HStack>
                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Course Start Date
                        </Text>
                        <Text fontWeight={600}>{registration.course_run_details.start_date}</Text>
                    </HStack>
                    <HStack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            Course End Date
                        </Text>
                        <Text fontWeight={600}>{registration.course_run_details.end_date}</Text>
                    </HStack>
                    <Stack mb={1}>
                        <Text w={200} color="fg.muted" fontWeight={400}>
                            <u>Venue</u>
                        </Text>
                        <Text fontWeight={400}>{registration.course_run_details.venue}<br/><br/>{registration.course_run_details.attachment}</Text>
                    </Stack>
                    <Text w={200} color="fg.muted" fontWeight={400}>
                        <u>Pre-course brief</u>
                    </Text>
                    <Text fontWeight={400}>
                        {registration.course_run_details.admin_brief}
                    </Text>
                </Stack>
            </Center>
        </Box>
    )
}

const protected_routes = [
    {
        path: '/home',
        element:
            <ProtectedRoute>
                <Home/>
            </ProtectedRoute>
    },
    {
        path: '/my-courses',
        element:
            <ProtectedRoute>
                <Courses/>
            </ProtectedRoute>
    },
    {
        path: '/enrolled-courses',
        element:
            <ProtectedRoute>
                <EnrolledCourses/>
            </ProtectedRoute>
    },
    {
        path: '/all-assessments',
        element:
            <ProtectedRoute>
                <AssessmentForCourses/>
            </ProtectedRoute>
    },
    {
        path: '/assessment/:assessmentID',
        element:
            <ProtectedRoute>
                <EachAssessment/>
            </ProtectedRoute>
    }

]


function App() {
    const {user,isLoading} = useAuth();
    const {signOut} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    console.log("User in App.js:", { user, isLoading });
    
    const [participantName, setParticipantName] = useState("");
    useEffect(() => {
        const fetchParticipantName = async () => {
            if (user) {
                const { data, error } = await supabase
                    .from("full_assessment_details")
                    .select("participant_name")
                    .eq("user_id", user.id)
                    .limit(1);

                if (data) {
                    setParticipantName(data[0].participant_name);
                } else if (error) {
                    console.error("Error fetching participant name:", error);
                }
            }
        };

        fetchParticipantName();
        console.log(participantName)
    }, [user]);

    const handleLogout = async () => {
        try {
            await signOut(); // Call signOut from the context
            navigate("/login");
          } catch (error) {
            console.error("Error logging out:", error);
          }
        };

    const isUnprotectedPage = ["/registration", "/attendance", "/confirmation", "/enrollment", "/success"].some((path) =>
        location.pathname.startsWith(path)
    );

    return (
            <AuthProvider>
                <Box bg={"#F4EEEA"}>
                    <Box as="section">
                        <Box
                            borderBottomWidth="1px"
                            bg="#0E1735"
                            position="relative"
                            zIndex="tooltip"
                        >
                            <Container py="4">
                                <HStack justify="space-between" spacing="8">
                                    <HStack spacing="3">
                                        <Image w={"80px"} src={"/sozcode-logo.png"}></Image>
                                    </HStack>
                                    {!isUnprotectedPage&&(
                                    <ButtonGroup
                                        size="lg"
                                        variant="text.accent"
                                        colorScheme="gray"
                                        spacing="8"
                                        display={{
                                            base: "none",
                                            lg: "flex",
                                        }}
                                        justify={"flex-start"}
                                    >
                                        <Link to="/enrolled-courses">
                                            <Button>Enrolled Courses</Button>
                                        </Link>
                                        <Link to="/all-assessments">
                                            <Button>Assessments</Button>
                                        </Link>
                                        <Link to="/appeals">
                                            <Button>Appeals</Button>
                                        </Link>
                                        {/*<Link to="/daily_updates">*/}
                                        {/*  <Button>Daily Updates</Button>*/}
                                        {/*</Link>*/}
                                        {/*<Link to="/chats">*/}
                                        {/*  <Button>Chats</Button>*/}
                                        {/*</Link>*/}
                                    </ButtonGroup>
                                    )}
                                    {!isUnprotectedPage&&(
                                    <HStack spacing="3">
                                        {user ? (
                                            <Menu>
                                            <MenuButton
                                                as={Button}
                                                rightIcon={<ChevronDownIcon />}
                                                colorScheme="teal"
                                            >
                                                Hello, {participantName||"Guest"}!
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => navigate("/profile")}>
                                                Profile
                                                </MenuItem>
                                                <MenuDivider />
                                                <MenuItem color="red" onClick={handleLogout}>
                                                Log Out
                                                </MenuItem>
                                            </MenuList>
                                            </Menu>
                                        ) : (
                                            <Button
                                            variant="outline"
                                            onClick={() => navigate("/login")}
                                            colorScheme="teal"
                                            >
                                            Log In
                                            </Button>
                                        )}
                                    </HStack>)}
                                    <HStack
                                        spacing={{
                                            base: "2",
                                            md: "4",
                                        }}
                                    >
                                        {/*<InputGroup*/}
                                        {/*    maxW="2xs"*/}
                                        {/*    display={{*/}
                                        {/*        base: "none",*/}
                                        {/*        md: "inline-flex",*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <InputLeftElement>*/}
                                        {/*        <Icon as={FiSearch} color="fg.accent.muted" fontSize="lg" />*/}
                                        {/*    </InputLeftElement>*/}
                                        {/*    <Input placeholder="Search" variant="filled.accent" />*/}
                                        {/*</InputGroup>*/}
                                        {/*<ButtonGroup variant="tertiary.accent" spacing="1">*/}
                                        {/*    <IconButton*/}
                                        {/*        icon={<FiSearch />}*/}
                                        {/*        aria-label="Search"*/}
                                        {/*        display={{*/}
                                        {/*            base: "flex",*/}
                                        {/*            lg: "none",*/}
                                        {/*        }}*/}
                                        {/*        isRound*/}
                                        {/*    />*/}
                                        {/*    <IconButton*/}
                                        {/*        icon={<FiBell />}*/}
                                        {/*        aria-label="Show notification"*/}
                                        {/*        isRound*/}
                                        {/*    />*/}
                                        {/*</ButtonGroup>*/}
                                        {/*<Menu>*/}
                                        {/*    <MenuButton as={Avatar} />*/}
                                        {/*    <MenuList>*/}
                                        {/*        <MenuGroup title="Profile">*/}
                                        {/*            <MenuItem>My Classes</MenuItem>*/}
                                        {/*            <MenuItem*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    navigate(`/profile/${profile.id}`);*/}
                                        {/*                }}*/}
                                        {/*            >*/}
                                        {/*                Settings*/}
                                        {/*            </MenuItem>*/}
                                        {/*        </MenuGroup>*/}
                                        {/*        <MenuDivider />*/}
                                        {/*        <MenuGroup title="Settings">*/}
                                        {/*            <MenuItem*/}
                                        {/*                onClick={async() => {*/}
                                        {/*                    await signOut();*/}
                                        {/*                    navigate("/login");*/}
                                        {/*                }}*/}
                                        {/*            >*/}
                                        {/*                Log out*/}
                                        {/*            </MenuItem>*/}
                                        {/*            <MenuItem></MenuItem>*/}
                                        {/*        </MenuGroup>*/}
                                        {/*    </MenuList>*/}
                                        {/*</Menu>*/}
                                    </HStack>
                                </HStack>
                            </Container>
                        </Box>
                    </Box>
                    <Container mt={10}>
                    <Routes>
                        {
                            protected_routes.map(route =>
                                <Route key={route.path} path={route.path} element={route.element}/>
                            )
                        }
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/confirmation/:registrationId" element={<Acknowledgement/>}/>
                        <Route path="/attendance/:courseRunId" element={<Attendance/>}/>
                        <Route path="/attendance" element={<AttendanceSelection/>}/>
                        <Route path="/enrolled-courses" element={<EnrolledCourses/>}/>
                        <Route path="/all-assessments" element={<AssessmentForCourses/>}/>
                        <Route path="/courses/:courseRunId" element={<EachCourse/>}/>
                        <Route path="/assessment/:assessmentID" element={<EachAssessment/>}/>
                        <Route path="/registration" element={<Registration/>}/>
                        {/* <Route path="/test-lms" element={<TestLMS/>}/> */}
                        <Route path="/enrollment/:registrationID" element={<Enrollment/>}/>
                        <Route path="/success/:registrationID" element={<SuccessPage/>}/>
                        <Route path="/success/registration/:registrationID" element={<SuccessRegistration/>}/>
                        <Route path="/success/acknowledgement/:assessmentID" element={<SuccessAcknowledgement/>}/>
                        
                    </Routes>
                    </Container>
                </Box>
            </AuthProvider>
    );
}

function RouterApp() {
    const {isLoading} = useAuth()
    console.log(isLoading)
    if (isLoading) {
        return (
            <div>
                Loading
            </div>
        )
    } else {
        return (
            <Outlet/>
        )
    }
}

export default App;
