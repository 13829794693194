// import React, {useEffect, useRef, useState} from "react";
// import {Link, useParams} from "react-router-dom";
// import {supabase} from "./utils/supabaseClient";
// import {
//     Box,
//     Button,
//     Center,
//     FormControl,
//     FormLabel,
//     Heading,
//     Select,
//     Stack,
//     Text,
//     Card,
//     Container, HStack, Badge
// } from "@chakra-ui/react";
// import {CheckCircleIcon} from "@chakra-ui/icons";

// export const EnrolledCourses = () => {
//     const [courses, setCourses] = useState([]);

//     const getAllEnrolledCourses = async () => {
//         try{
//         const { data, error } = await supabase
//             .from("full_registration_details_with_enrollment")
//             .select(
//                 "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality,course_dates, user_id"
//             )
//             .gte("start_date", new Date().toLocaleDateString('fr-CA'))
//             .eq("user_id", "126130d7-c923-4fec-bf5f-108944f39371") //need session ID
//             //.single();

//         if (error) {
//             console.error("Error fetching courses:", error); 
//             return;
//           }

//         setCourses(data || []); // Set courses or empty array
//         } catch (error) {
//           console.error("Unexpected error fetching courses:", error);
//         }
//         };
      
//         // const data = [
//         //     {
//         //         "name": "Python and GPT4 (ChatGPT) For Beginners",
//         //         "start_date": "2024-11-13",
//         //         "end_date": "2024-11-14",
//         //         "id": "jdijd930-jdndje-2832892n-jdnusn",
//         //         "progress": "Not Completed"
//         //     },
//         //     {
//         //         "name": "Master ChatGPT and AI For Everyday Work",
//         //         "start_date": "2024-11-20",
//         //         "end_date": "2024-11-20",
//         //         "id": "jdijd930-jdndje-2832892n-jdnusn",
//         //         "progress": "Not Completed"
//         //     },
//         // ]
     
// useEffect(() => {
//     getAllEnrolledCourses();
// }, []);

// return (
//             <Box justifyContent={"space-between"}>
//                 <Heading size={"md"}>Resources For Your Enrolled Courses</Heading>
//                 {
//                     courses.map(each =>
//                         <Card maxW={"400px"} alignItems={"flex-start"} p={5} mb={5} mr={5} mt={5}>
//                             <Badge mb={5}>{each.progress}</Badge>
//                             <Box>
//                                 <Heading size={"2xs"} mb={5}>{each.name}</Heading>
//                                 <HStack mb={2}>
//                                     <Text w={200} color="fg.muted" fontWeight={300}>
//                                         Start Date
//                                     </Text>
//                                     <Text>{each.start_date}</Text>
//                                 </HStack>
//                                 <HStack mb={2}>
//                                     <Text w={200} color="fg.muted" fontWeight={300}>
//                                         End Date
//                                     </Text>
//                                     <Text>{each.end_date}</Text>
//                                 </HStack>
//                             </Box>
//                             <Link to={`/courses/${each.id}`}>
//                                 <Button variant={"link"} mt={5}>View resources</Button>
//                             </Link>
//                         </Card>
//                     )
//                 }
//             </Box>
//     )
// }

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
  Box,
  Button,
  Heading,
  Card,
  HStack,
  Badge,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAuth } from "./providers/AuthProvider";


export const EnrolledCourses = () => {
  const {auth, isLoading} = useAuth();
  const {user} = useAuth();
  const [courses, setCourses] = useState([]);
  console.log("Enrolledcourses User",user.id)

  const getAllEnrolledCourses = async () => {
    try {
      const { data, error } = await supabase
        .from("full_registration_details_with_enrollment")
        .select(
          "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality, course_dates, user_id"
        )
        .gte("end_date", new Date().toLocaleDateString("fr-CA"))
        .eq("user_id", user.id); 

      if (error) {
        console.error("Error fetching courses:", error);
        return;
      }

      // Sort courses by start_date
      const sortedCourses = data.sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date);
      });

      setCourses(sortedCourses || []); // Set courses or empty array
    } catch (error) {
      console.error("Unexpected error fetching courses:", error);
    }
  };

  useEffect(() => {
    getAllEnrolledCourses();
  }, []);

  return (
    <Box justifyContent={"space-between"} p={5}>
      <Heading size={"md"} mb={5}>
        Resources For Your Enrolled Courses
      </Heading>
      {courses.map((each) => (
        <Card key={each.id} maxW={"400px"} alignItems={"flex-start"} p={5} mb={5}>
          <Badge mb={5} colorScheme="blue">
            {each.registration_stage || "No Progress"}
          </Badge>
          <Box>
            <Heading size={"sm"} mb={3}>
              {each.name}
            </Heading>
            <HStack mb={2}>
              <Text w={200} fontWeight={300}>
                Start Date
              </Text>
              <Text>{each.start_date}</Text>
            </HStack>
            <HStack mb={2}>
              <Text w={200} fontWeight={300}>
                End Date
              </Text>
              <Text>{each.end_date}</Text>
            </HStack>
            <VStack align="flex-start" mt={3}>
              <Heading size="xs" mb={1}>
                Scheduled Dates:
            </Heading>
              {each.course_dates && each.course_dates.length > 0 ? (
                each.course_dates.map((session, index) => (
                  <HStack key={index} spacing={3}>
                    <Text>Date: {session.date}</Text>
                    <Text>
                      Time: {session.start_time} - {session.end_time}
                    </Text>
                  </HStack>
                ))
              ) : each.start_date === each.end_date ? (
                // One-day course fallback
                <HStack>
                  <Text>Date: {each.start_date}</Text>
                  <Text>Time: 0900-1700</Text>
                </HStack>
              ) : (
                // Multi-day course fallback
                <>
                  <HStack>
                    <Text>Date: {each.start_date}</Text>
                    <Text>Time: 0900-1700</Text>
                  </HStack>
                  <HStack>
                    <Text>Date: {each.end_date}</Text>
                    <Text>Time: 0900-1700</Text>
                  </HStack>
                </>
              )}
            </VStack>
          </Box>
          <Link to={`/courses/${each.id}`}>
            <Button variant={"link"} mt={5}>
              View resources
            </Button>
          </Link>
        </Card>
      ))}
    </Box>
  );
};