import React, {useEffect, useRef, useState} from "react";
import {useParams, useLocation, useNavigate} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {
    Alert,
    Box,
    Button,
    Center,
    Circle, Container, Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack, Input, Radio, RadioGroup,
    Select, Spinner,
    Stack, Tab, TabList, TabPanel, TabPanels, Tabs,
    Text,
    AlertIcon, useToast, useRadio, useRadioGroup,
    Tbody,Tr,Td,Table
} from "@chakra-ui/react";
import {HiCheck, HiOutlineDocument, HiDocumentText, HiAnnotation, HiLightBulb, HiPaperAirplane} from 'react-icons/hi'

import {CheckCircleIcon, Icon} from "@chakra-ui/icons";
import SignaturePad from "react-signature-canvas";


const Step1 = (props) => {
    const {courseRuns, formData, setFormData, allowedTab, setTabIndex} = props

    const navigate = useNavigate();
    const location = useLocation();
    

    // Update form state based on URL params when the component mounts
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setFormData((prevData)=>({
            ...prevData,
            registration_type: params.get('registration_type') || '',
            course_run_id: params.get('course_run_id') || '',
            course_type_code: params.get('course_type_code') || '',
            id: prevData.id || '',
        }));
    }, [location.search]);

    // Function to update form data and sync with URL params
    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            id: name === "course_run_id" ? prevData.id:prevData.id // preserve id unless course_run_id changes
        }));
        if (name === "course_run_id" || name === "course_type_code" || name === "registration_type") {
            const params = new URLSearchParams(location.search);
            params.set(name, value);
            navigate({ search: params.toString() }, { replace: true });
        }

    };

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'attendance_id',
        defaultValue: '',
        onChange: (value) => {
            console.log("Selected course_run_id:",value);
            //handleChange("course_run_id", value);
            const selectedCourse = courseRuns.find(
                (course)=>course.attendance_id === value); //course is the single object within the courseRun array
            if (selectedCourse) { //if found a match then selectedCourse will have value = true
                console.log("Selected course id:", selectedCourse.id);
                // Update formData with course_run_id and id
                setFormData((prevData)=>({...prevData,course_run_id:value,id:selectedCourse.id}))
                // handleChange("course_run_id", value);
                // handleChange("id", selectedCourse.id); // Add `id` to formData
                } else {
                    console.error("Selected course not found!");}
            

        }
    }) //course run id will here is the uuid for each unique course

    const group = getRootProps()
    console.log(formData.id) //value logged 
    //console.log("COURSE RUNS", courseRuns, formData) //course run only has 3 object details: course_run_id, course_type_code and registration_type
    
    //FORM VALIDATION RULES
    
    const arePrerequisitesFilled = () => {
        return formData.nationality && formData.birth_age && formData.registration_type;
    };

    return (
        <Box w={"100%"}>
            <FormControl>
                <FormLabel>Registration Type</FormLabel>
                <Select name="registration_type" value={formData.registration_type} placeholder='Select option' bg={"white"} onChange={(e) => handleChange("registration_type", e.target.value)}>
                    <option value={"Self-Sponsored"} label={"Self-Sponsored"}></option>
                    <option value={"Company-Sponsored"} label={"Company-Sponsored"}></option>
                    <option value={"HRP"} label={"HRP Registration (For Public Officers)"}></option>
                </Select>
            </FormControl>
            <FormControl mt={5}>
                <FormLabel>Nationality</FormLabel>
                <Select name="nationality" value={formData.nationality} placeholder='Select option' bg={"white"}
                        onChange={(e) => handleChange("nationality", e.target.value)}>
                        <option value="Singapore Citizen">Singapore Citizen</option>
                        <option value="Singapore Permanent Resident">Singapore Permanent Resident</option>
                        <option value="Long Term Visitor Pass+ (LTVP+)">Long Term Visitor Pass+ (LTVP+)</option>
                        <option value="Non-Singapore Citizen/PR (FIN)">Non-Singapore Citizen/PR (FIN)</option>
                        <option value="Non-Singapore Resident (Foreign Passport)">Non-Singapore Resident (Foreign Passport)</option>
                </Select>
            </FormControl>
            <FormControl mt={5}>
                <FormLabel>Age (Based On Birth Year)</FormLabel>
                <Input
                    value={formData.birth_age}
                    onChange={(e) => {
                        const age = e.target.value;

                        // Allow only numeric input or empty value
                        if (/^\d*$/.test(age)) {
                            if (age === "" || Number(age) <= 150) {
                                handleChange("birth_age", age); // Update state only for valid input
                            } else {
                                alert("Please enter a valid age :P");
                                handleChange("birth_age", ""); // Clear invalid input
                            }
                        } else {
                            alert("Please enter a valid age in numeric format.");
                            handleChange("birth_age", ""); // Clear invalid input
                        }
                    }}
                    size="sm"
                    type="text" // Change to "text" to allow non-numeric input for validation
                />
            </FormControl>
            <FormControl mt={5}>
                <FormLabel>Course Type</FormLabel>
                <Select name="course_type" placeholder='Select option after filling the above' value={formData.course_type_code} bg={"white"}
                        onChange={(e) => handleChange("course_type_code", e.target.value)} isDisabled={!arePrerequisitesFilled()}>
                    {[...new Set(courseRuns.map(each => `${each.course_type_code}:${each.name}`))].map((each, index) => (
                        <option key={index} value={each.split(":")[0]} label={each.split(":")[1]} />
                    ))}
                </Select>
            </FormControl>
            <FormControl mt={5}>
                    <FormLabel>Course Run</FormLabel>
                    <HStack {...group} flexWrap={"wrap"}>
                        {courseRuns.filter(each => each.course_type_code === formData.course_type_code).map((option) => {
                            const radio = getRadioProps({ value: option.attendance_id });
                            const nettPrice = () => {
                                if (parseInt(formData.birth_age) >= 40 && formData.nationality !== "Non-Singapore Resident (Foreign Passport)" && formData.nationality !== "Non-Singapore Citizen/PR (FIN)") {
                                    return 0.7 * option.price
                                } else if (parseInt(formData.birth_age) < 40 && formData.nationality !== "Non-Singapore Resident (Foreign Passport)" && formData.nationality !== "Non-Singapore Citizen/PR (FIN)") {
                                    return 0.5 * option.price
                                } else {
                                    return option.price;
                                }
                            }
                            return (
                                <RadioCard
                                    key={option.attendance_id}
                                    {...radio}
                                    course_name={option.name}
                                    start_date={option.start_date}
                                    end_date={option.end_date}
                                    price={option.price}
                                    nett_price={nettPrice()}
                                    course_dates={option.course_dates}
                                />
                            );
                        })}
                    </HStack>
                </FormControl>
        </Box>
    )
}

function RadioCard(props) {
    const { getInputProps, getRadioProps } = useRadio(props)
    const {course_name, start_date, end_date, price, nett_price,course_dates} = props
    const input = getInputProps()
    const checkbox = getRadioProps()

    return (
        <Box as='label' w={300}>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={5}
                py={3}
            >
                <Text fontSize={"sm"} mb={4}>{course_name}</Text>
                <Text fontSize={"xs"}>Start Date: {start_date}</Text>
                <Text fontSize={"xs"}>End Date: {end_date}</Text>
                {course_dates?.length > 0 && (
                        <><Text fontSize={"xs"}>Course Dates:</Text>
                        <Table variant="simple" size="sm" bg="transparent">
                            <Tbody>
                                {course_dates?.map((session, index) => (
                                    <Tr key={index}>
                                        <Td textAlign="center" fontSize="xs" p={1} bg="transparent">{session.date}</Td>
                                        <Td textAlign="center" fontSize="xs" p={1} bg="transparent">{session.start_time}</Td>
                                        <Td textAlign="center" fontSize="xs" p={1} bg="transparent">{session.end_time}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table></>
                    
                )}
                
                <Text fontSize={"xs"}>Course Fees Before Subsidy: SGD {price}</Text>
                <Text fontSize={"xs"}>Nett Course Fees Payable: SGD {nett_price}</Text>
            </Box>
        </Box>
    )
}

const Step2 = (props) => {
    const {courseRuns,course_run_id,setFormData, formData, allowedTab, setTabIndex} = props
    const [isSubmitting, setIsSubmitting] = useState(false)

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const registration_type = params.get("registration_type")

    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    //const selected_course_run = courseRuns.filter((each) => each.attendance_id === course_run_id)[0]
    //const log_input_data = () => {console.log(formData)}
    const navigate = useNavigate();
    const insertRegistration = async () => {
        setIsSubmitting(true)
        try {
            const { data, error } = await supabase
                .from('registration') 
                .insert([
                    {
                        participant_name: formData.participant_name, 
                        participant_email: formData.participant_email, 
                        registration_type: formData.registration_type, 
                        nationality: formData.nationality, 
                        birth_age: formData.birth_age, 
                        mobile_no: formData.mobile_no, 
                        course_run_id: formData.id, 
                        registration_stage: "pending" 
                    },
                ])
                .select("id,participant_email") //Ensures only the specified fields (id and participant_email) are included in the response.
                .single();//Returns a single object for easier handling when you expect only one row in the result.

            if (error) {
                setIsSubmitting(false)
                console.error("Error inserting data:", error);
                alert("Failed to register. Please try again.");
            } else {
                console.log("Registration successful:", data);
                // Trigger the webhook by visiting the URL
                await fetch("https://eo3f0jtagjlr5to.m.pipedream.net", {
                    method: "POST", 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        registration_id: data.id,
                    }),// Use GET if no data needs to be sent //Post data.id -> POST -> find row in using the id 3.1.3a-3
                });
                setIsSubmitting(false)
                navigate(`/success/registration/${data.id}`)
            }
        } catch (error) {
            console.error("Unexpected error:", error);
        }
    };


    return (
        <Box>
            {registration_type === "Self-Sponsored"
                ?
                <Box>
                    <FormControl mt={5}>
                        <FormLabel>Participant Name</FormLabel>
                        <Input
                            value={formData.participant_name}
                            onChange={(e) => handleChange("participant_name", e.target.value)}
                            size='sm'
                            type={"text"}
                        />
                    </FormControl>
                    <FormControl mt={5}>
                        <FormLabel>Participant Email</FormLabel>
                        <Input
                            value={formData.participant_email}
                            onChange={(e) => handleChange("participant_email", e.target.value)}
                            onBlur={(e) => {
                                const email = e.target.value;
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                if (!emailRegex.test(email) && email !== "") {
                                    alert("Please enter a valid email address.");
                                    // Clear the input field
                                    handleChange("participant_email", ""); 
                                }
                            }}
                            size="sm"
                            type="email"
                        />
                    </FormControl>
                    <FormControl mt={5}>
                        <FormLabel>Participant Mobile No.</FormLabel>
                        <Input
                            value={formData.mobile_no}
                            onChange={(e) => handleChange("mobile_no", e.target.value)}
                            onBlur={(e) => {
                                const mobileNo = e.target.value;
                                const mobileNoRegex = /^\d{8,15}$/; // Validates 8 to 15 digit numbers

                                if (!mobileNoRegex.test(mobileNo) && mobileNo !== "") {
                                    alert("Please enter a valid mobile number");
                                    // Clear the input field
                                    handleChange("mobile_no", "");
                                }
                            }}
                            size="sm"
                            type="text"
                        />
                    </FormControl>
                </Box>
                :
                <Box>
                    <FormControl mt={5}>
                        <FormLabel>Participant Name</FormLabel>
                        <Input
                            value={formData.participant_name}
                            onChange={(e) => handleChange("participant_name", e.target.value)}
                            size='sm'
                            type={"text"}
                        />
                    </FormControl>
                    <FormControl mt={5}>
                        <FormLabel>Participant Email</FormLabel>
                        <Input
                            value={formData.participant_email}
                            onChange={(e) => handleChange("participant_email", e.target.value)}
                            onBlur={(e) => {
                                const email = e.target.value;
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                if (!emailRegex.test(email) && email !== "") {
                                    alert("Please enter a valid email address.");
                                    // Clear the input field
                                    handleChange("participant_email", ""); 
                                }
                            }}
                            size="sm"
                            type="email"
                        />
                    </FormControl>
                    <FormControl mt={5}>
                        <FormLabel>Participant Mobile No.</FormLabel>
                        <Input
                            value={formData.mobile_no}
                            onChange={(e) => handleChange("mobile_no", e.target.value)}
                            onBlur={(e) => {
                                const mobileNo = e.target.value;
                                const mobileNoRegex = /^\d{8,15}$/; // Validates 8 to 15 digit numbers

                                if (!mobileNoRegex.test(mobileNo) && mobileNo !== "") {
                                    alert("Please enter a valid mobile number");
                                    // Clear the input field
                                    handleChange("mobile_no", "");
                                }
                            }}
                            size="sm"
                            type="text"
                        />
                    </FormControl>
                </Box>
            }
            <Button isLoading={isSubmitting}  mt="10" mb="8" size={"lg"} isDisabled={allowedTab < 2} onClick={insertRegistration}>
                Submit Registration
            </Button> 
            {/* //insertRegistration */}
        </Box>
    )
}

const Checkout = (props) => {
    const {sigPad, formData, setFormData, courseRuns, allowedTab, setTabIndex} = props

    return (
        <Box>
            <Spinner/>
            <Text>Please wait as we redirect you to checkout</Text>
        </Box>
    )
}

const Step3 = (props) => {
    const {sigPad, formData, setFormData, courseRuns, allowedTab, setTabIndex} = props
    const [submitting, setSubmitting] = useState(false)
    console.log(sigPad.current?.isEmpty())
    const clearSignature = () => {
        sigPad.current.clear();
    };
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const course_run_id = params.get("course_run_id")
    const selected_course_run = courseRuns.filter((each) => each.attendance_id === course_run_id)[0]
    const toast = useToast()

    const insertRegistration = async () => {
        setSubmitting(true);
        try {
            const { data, error } = await supabase
                .from('registration')
                .insert([
                    {
                        participant_name: formData?.participant_name,
                        participant_email: formData?.participant_email,
                        billing_type: formData?.billing_type,
                        registration_type: formData?.registration_type,
                        signature: formData?.signature,
                        course_run_id: selected_course_run.id,
                        registration_stage: "pending"
                    },
                ])
                .select("id, participant_email")
                .single();

            if (error) throw error;

            return data;
        } catch (error) {
            console.error('Error inserting registration:', error);
            setSubmitting(false);
            throw error; // Re-throw error so it can be caught later
        }
    };

    const handleCheckout = async (registration) => {
        console.log(registration)
        try {
            const response = await fetch('https://eovhmnu1acpextc.m.pipedream.net', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    product_id: selected_course_run.course_type_code,
                    registration_id: registration.id,
                    participant_email: registration.participant_email
                }),
            });

            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
                setSubmitting(false);
            } else {
                console.error('No URL found in response');
            }
        } catch (error) {
            console.error('Error during the checkout process:', error);
        }
    };

    const saveSignature = async () => {
        if (sigPad.current?.isEmpty()) {
            toast({
                title: "Sign the student contract before proceeding to checkout",
                status: "error",
                isClosable: true,
                position: "top",
            });
        } else {
            try {
                if (sigPad.current) {
                    const dataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
                    console.log(dataURL);

                    // Update formData with the signature
                    setFormData((prevData) => ({
                        ...prevData,
                        signature: dataURL,
                    }));

                    // Insert the registration and await its result
                    const registrationData = await insertRegistration();

                    if (registrationData) {
                        // Proceed to checkout with the registration data
                        await handleCheckout(registrationData);
                    }
                }
            } catch (error) {
                console.error('Error in saveSignature process:', error);
            }
        }
    };

    return (
        <div>
            <Alert status='info' mb={8}>
                <AlertIcon />
                Please view the terms of the Student Contract carefully before proceeding to checkout
            </Alert>
                <Text fontSize={"lg"} as={"b"}>STUDENT CONTRACT (FOR SELF-SPONSORED)</Text>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            This contract binds the Training Provider and the Student once both parties sign this Contract.
            This contract is made between:
                <br/>
                <br/>
                <ol type="a" style={{marginLeft: "20px", listStyleType: "lower-alpha"}}>
                    <li>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Registered Name of Training Provider:
                            </Text>
                            <Text>SOZCODE PTE. LTD</Text>
                            Registration Number: 202139596G<br/>
                        </HStack>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Registration Number:
                            </Text>
                            <Text>202139596G</Text>
                        </HStack>
                    </li><br/>
                    <li>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Student Name:
                            </Text>
                            <Text>{formData?.participant_name}</Text>
                        </HStack>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Email:
                            </Text>
                            <Text>{formData?.participant_email}</Text>
                        </HStack>

                    </li>
                </ol>
                <br/>
                <br/>
           <strong>Course Information</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course Title:
                </Text>
                <Text>{selected_course_run?.name}</Text>
            </HStack>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course Commencement Date:
                </Text>
                <Text>{selected_course_run?.start_date}</Text>
            </HStack>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course End Date:
                </Text>
                <Text>{selected_course_run?.end_date}</Text>
            </HStack>
            <br/>
            <br/>
                <strong>Course Fees</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <table style={{border: "1px solid black", width: "100%"}}>
                    <thead>
                    <tr>
                        <th style={{border: "1px solid black"}}>
                            <td>Fee Breakdown</td>
                        </th>
                        <th style={{border: "1px solid black"}}>
                            <td>Amount</td>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr style={{border: "1px solid black"}}>
                            <td style={{border: "1px solid black"}}>
                                Full Course Fee
                            </td>
                            <td style={{border: "1px solid black"}}>
                                SGD {selected_course_run?.price}
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: "1px solid black"}}>
                                Nett fee payable by individual
                            </td>
                            <td style={{border: "1px solid black"}}>
                                SGD {selected_course_run?.price}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <strong>Refund/Withdrawal and Rescheduling Policy</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <table style={{border: "1px solid black", width: "100%"}}>
                    <thead>


                    <tr>
                        <th style={{border: "1px solid black"}}>
                            <td>Request for Withdrawal</td>
                        </th>
                        <th style={{border: "1px solid black"}}>
                            <td>Options for student</td>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{border: "1px solid black"}}>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made before sending of course confirmation email
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Cancel with no cancellation charges
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made at least 10 working days before the course start date
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Re-scheduling for other course runs (if available)<br/>
                            ·   	Send someone else in your place<br/>
                            ·   	Cancel with 100% course fees still payable<br/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made less than 10 working days before the course start date
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Send someone else in your place<br/>
                            ·   	Cancel with 100% course fees still payable<br/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            <br/>
                <br/>

            <ol type="a" style={{marginLeft: "20px", listStyleType: "lower-alpha"}}>
                    <li>The Training Provider reserves the right to change or cancel the course due to unforeseen circumstances. A full refund of any course fees paid will be made to the Student in this circumstance.

                    </li><br/>
                    <li>A withdrawal request can be made via sending an email to courses@sozcode.com.

                    </li><br/>
                </ol>
            <br/>
            <br/>
            <FormLabel>            I hereby acknowledge and agree to the terms stated in this Contract.
            </FormLabel>
            <Box w={300}>
                <SignaturePad ref={sigPad} canvasProps={{ className: 'signatureCanvas', height: '300px', width: '400px' }} />
            </Box>
            <Box>
                <Button mt="1" mb="8" maxW={200} minW={100}  w="20%" size={"xs"} variant={"outline"} onClick={clearSignature}>
                    Clear Signature
                </Button>
            </Box>
            <Box>
                <Button  mt="4" mb="8" size={"lg"} isLoading={submitting} onClick={saveSignature}>
                    Proceed to Payment
                </Button>
            </Box>
        </div>
    )
}

export const Registration = () => {
    const [courseRuns, setCourseRuns] = useState([]);
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(null)
    const [loadingCourseRuns, setLoadingCourseRuns] = useState(false)
    const { courseRunId } = useParams();
    const sigPad = useRef(null);
    const [formData, setFormData] = useState({
        participant_name: '',
        participant_email: '',
        registration_type: '',
        course_run_id: '',
        signature: ''
    });

    const [tabIndex, setTabIndex] = useState(0)
    const [allowedTab, setAllowedTab] = useState(0)

    const handleTabsChange = (index) => {
        setTabIndex(index)
    }



    useEffect(() => {
        const steps = [
            ['course_run_id', 'registration_type'],                       // Step 1
            ['course_run_id', 'registration_type', 'participant_name', 'participant_email'],  // Step 2
            ['course_run_id', 'registration_type', 'participant_name', 'participant_email', 'signature'] // Step 3
        ];

        let allowedTab = 0;

        for (let i = 0; i < steps.length; i++) {
            if (steps[i].every(field => formData[field])) {
                allowedTab = i + 1;
            } else {
                break;
            }
        }
        setAllowedTab(allowedTab);
        console.log(allowedTab, formData)

    }, [formData])

    const getAllCourseRuns = async () => {
        setLoadingCourseRuns(true)
        const { data, error } = await supabase
            .from("course_run_details")
            .select("*")
            .gte("start_date", new Date().toLocaleDateString('fr-CA')) //Greater than/equal to today's date then will be shown
            .order("start_date")

        if (data) {
            console.log(data);
            setCourseRuns(data);
            setLoadingCourseRuns(false)
        }
        if (error) {
            console.log(error);
            setLoadingCourseRuns(false)
        }
    };



    useEffect(() => {
        getAllCourseRuns();
    }, []);




    return (
            <Container w={"100%"}  display="flex" justifyContent="center" alignItems="center" >
                {
                    loadingCourseRuns
                        ?
                        <Spinner />
                        :
                        <Stack spacing="4" width="100%" bg={"white"} maxW={"95%"} px={10} py={10}>
                            <Heading size={"sm"} mb={10}>
                                Course Registration
                            </Heading>
                            <Step1 courseRuns={courseRuns} formData={formData} setFormData={setFormData} allowedTab={allowedTab} setTabIndex={setTabIndex}/>
                            <Step2 formData={formData} setFormData={setFormData} allowedTab={allowedTab} setTabIndex={setTabIndex}/>
                            {/*<Tabs index={tabIndex} onChange={handleTabsChange}>*/}
                            {/*    <TabList>*/}
                            {/*        <Tab isDisabled={allowedTab <= 0}>1. Registration</Tab>*/}
                            {/*        <Tab isDisabled={allowedTab < 1}>2. Participant Details</Tab>*/}
                            {/*        <Tab isDisabled={allowedTab < 2}>3. Student Contract</Tab>*/}
                            {/*        <Tab isDisabled={allowedTab < 3}>4. Checkout</Tab>*/}
                            {/*    </TabList>*/}
                            {/*    <TabPanels>*/}
                            {/*        <TabPanel>*/}
                            {/*            <Step1 courseRuns={courseRuns} formData={formData} setFormData={setFormData} allowedTab={allowedTab} setTabIndex={setTabIndex}/>*/}
                            {/*        </TabPanel>*/}
                            {/*        <TabPanel>*/}
                            {/*            <Step2 formData={formData} setFormData={setFormData} allowedTab={allowedTab} setTabIndex={setTabIndex}/>*/}
                            {/*        </TabPanel>*/}
                            {/*        <TabPanel>*/}
                            {/*            <Step3 sigPad={sigPad} formData={formData} setFormData={setFormData} courseRuns={courseRuns} allowedTab={allowedTab} setTabIndex={setTabIndex}/>*/}
                            {/*        </TabPanel>*/}
                            {/*        <TabPanel>*/}
                            {/*            <Checkout formData={formData} setFormData={setFormData} courseRuns={courseRuns} allowedTab={allowedTab} setTabIndex={setTabIndex}/>*/}
                            {/*        </TabPanel>*/}
                            {/*    </TabPanels>*/}
                            {/*</Tabs>*/}
                        </Stack>
                }
            </Container>
    );
};
