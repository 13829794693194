import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import { useNavigate } from "react-router-dom"; 
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ListItem,
  UnorderedList,
  Image
} from "@chakra-ui/react";
import SignaturePad from "react-signature-canvas";

export const Enrollment = () => {
  const { registrationID } = useParams();
  const [registrationData, setRegistrationData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const sigPad = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();

  console.log("Registration ID from URL:", registrationID);

  // Fetch registration data
  useEffect(() => {
    const fetchRegistration = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from("full_registration_details_with_enrollment")
          .select(
            "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality, course_dates,code, course_objectives,course_duration,minimum_entry_requirements,prerequisites,funding_validity_period"
          )
          // .gte("start_date", new Date().toLocaleDateString('fr-CA'))
          .eq("id", registrationID)
          .single();

        if (error) throw error;

        setRegistrationData(data);
      } catch (error) {
        console.error("Error fetching registration:", error);
        toast({
          title: "Error",
          description: "Could not load registration details.",
          status: "error",
          isClosable: true,
          position: "top",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchRegistration();
  }, [registrationID, toast]);

  console.log(registrationData)

  // Save Signature and Proceed
  const saveSignatureAndProceed = async () => {
    if (sigPad.current?.isEmpty()) {
      toast({
        title: "Error",
        description: "Please sign the contract before proceeding.",
        status: "error",
        isClosable: true,
        position: "top",
      });
      return;
    }
  
    setSubmitting(true);
    try {
      // Save signature
      const signature = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
      const { error: updateError } = await supabase
        .from("registration")
        .update({ signature })
        .eq("id", registrationID);
  
      if (updateError) {
        throw new Error("Failed to update signature in Supabase");
      }
  
      toast({
        title: "Signature Saved",
        description: "Your signature has been saved successfully.",
        status: "success",
        isClosable: true,
        position: "top",
        
      })
      toast({
        title: "Redirecting to Stripe payment page",
        description: "Please wait as we direct you to the payment page",
        status: "info",
        isClosable: true,
        position: "top",});
  
      // Call the Supabase Edge Function
      const { data, error } = await supabase.functions.invoke("create-checkout-session", {
        body: {
          product_id: registrationData.code,
          registration_id: registrationID,
          participant_email: registrationData.participant_email,
        },
      });

      console.log(data)
  
      if (error) {
        throw new Error(`Failed to create Stripe checkout session: ${error.message}`);
      }
  
      if (!data?.url) {
        throw new Error("Stripe checkout URL not found in the response.");
      }
  
      // Redirect to Stripe checkout page
      window.location.href = data.url;
  
    } catch (error) {
      console.error("Error during process:", error);
  
      // Handle errors with appropriate messages
      const errorMessage = error.message || "An unknown error occurred. Please try again.";
      let description = errorMessage;
  
      if (errorMessage.includes("Failed to update signature")) {
        description = "Could not save signature to Supabase. Please try again.";
      } else if (errorMessage.includes("Failed to create Stripe checkout session")) {
        description = "Signature saved, but unable to connect to Stripe checkout. Please contact support.";
      }
  
      toast({
        title: "Error",
        description,
        status: "error",
        isClosable: true,
        position: "top",
      });
    } finally {
      setSubmitting(false);
    }
  };
//   const saveSignatureAndProceed = async () => {
//     if (sigPad.current?.isEmpty()) {
//       toast({
//         title: "Error",
//         description: "Please sign the contract before proceeding.",
//         status: "error",
//         isClosable: true,
//         position: "top",
//       });
//       return;
//     }

//     setSubmitting(true);
//     try {
//       // Save signature
//       const signature = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
//       const { error: updateError } = await supabase
//         .from("registration")
//         .update({ signature })
//         .eq("id", registrationID);

//         if (updateError) {
//             throw new Error("Failed to update signature in Supabase");
//           }
      
//           toast({
//             title: "Signature Saved",
//             description: "Your signature has been saved successfully.",
//             status: "success",
//             isClosable: true,
//             position: "top",
//           });

//           const response = await fetch("http://localhost:54321/functions/v1/create-checkout-session", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               product_id: registrationData.code, 
//               registration_id: registrationID,
//               participant_email: registrationData.participant_email,
//             }),
//           });

//           if (!response.ok) {
//             const errorDetails = await response.text();
//             throw new Error(`Failed to create Stripe checkout session: ${response.status} ${errorDetails}`);
//           }
      
//           const data = await response.json();
      
//           if (!data.url) {
//             throw new Error("Stripe checkout URL not found in the response.");
//           }
          
      
//           // Redirect to Stripe checkout page
//           window.location.href = data.url;

//         } catch (error) {
//           console.error("Error during process:", error);
//           if (error.message.includes("Failed to update signature")) {
//             toast({
//               title: "Error",
//               description: "Could not save signature to Supabase. Please try again.",
//               status: "error",
//               isClosable: true,
//               position: "top",
//             });
//           } else if (error.message.includes("Failed to create Stripe checkout session")) {
//             toast({
//               title: "Error",
//               description: "Signature saved, but unable to connect to Stripe checkout. Please contact support.",
//               status: "error",
//               isClosable: true,
//               position: "top",
//             });
//           } else {
//             toast({
//               title: "Error",
//               description: error.message || "An unknown error occurred. Please try again.",
//               status: "error",
//               isClosable: true,
//               position: "top",
//             });
//           }
//         } finally {
//           setSubmitting(false);
//         }
//       };

      //if (error) throw error;

      // Trigger Checkout
    //   const response = await fetch(
    //     "https://eovhmnu1acpextc.m.pipedream.net",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         product_id: registrationData.course_run_id,
    //         registration_id: registrationData.id,
    //         participant_email: registrationData.participant_email,
    //       }),
    //     }
    //   );

//       const data = await response.json();
//       if (data.url) {
//         window.location.href = data.url;
//       } else {
//         throw new Error("No URL found in response");
//       }

//       toast({
//         title: "Success",
//         description: "Signature saved and checkout initiated!",
//         status: "success",
//         isClosable: true,
//         position: "top",
//       });
//     } catch (error) {
//       console.error("Error during save and checkout process:", error);
//       toast({
//         title: "Error",
//         description: "Failed to proceed. Please try again.",
//         status: "error",
//         isClosable: true,
//         position: "top",
//       });
//     } finally {
//       setSubmitting(false);
//     }
//   };

  // Clear Signature
  const clearSignature = () => {
    sigPad.current.clear();
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Box>
    );
  }

  if (!registrationData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Text>No registration data found.</Text>
      </Box>
    );
  }

  return (
    <Box p={8}>
      <Alert status="info" mb={8}>
        <AlertIcon />
        Please view the terms of the Student Contract carefully before proceeding to checkout.
      </Alert>
      <Text fontSize="lg" as="b">
        STUDENT CONTRACT
      </Text>
      <Divider my={4} />
      <Text>
        This contract binds the Training Provider and the Student once both parties sign this
        Contract.
      </Text>
      <Divider my={4} />
      <Text>This contract is made between:</Text>
      <VStack align="start" spacing={4} mt={4}>
        <Box>
          <Text fontWeight="bold">a) Registered Name of Training Provider:</Text>
          <Text>SOZCODE PTE. LTD.</Text>
          <Text>Registration Number: 202139596G</Text>
        </Box>
        <Box>
          <Text fontWeight="bold">b) Student Name:</Text>
          <Text>{registrationData.participant_name}</Text>
        </Box>
      </VStack>
      <Divider my={6} />
      <Text fontSize="lg" fontWeight="bold">
        1. Course Information
      </Text>
      <VStack align="start" spacing={3} mt={4}>
        <HStack>
          <Text fontWeight="bold">a) Course Title:</Text>
          <Text>{registrationData.name}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">b) Course Objectives:</Text>
          <Text>
              {
                  registrationData?.course_objectives
                      ?
                      registrationData.course_objectives
                      :
                      "N.A."
              }
          </Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">c) Course Duration:</Text>
          <Text>15 hours</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">d) Full-time or Part-time Course:</Text>
          <Text>Part-time</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">e) Attendance Requirements:</Text>
          <Text>&gt;75%</Text>
        </HStack>
        <VStack align="start" spacing={4}>
        <HStack>
            <Text fontWeight="bold">f) Course Start Date:</Text>
            <Text>{registrationData.start_date}</Text>
        </HStack>
        <HStack>
            <Text fontWeight="bold">g) Course End Date:</Text>
            <Text>{registrationData.end_date}</Text>
        </HStack>
        <Text fontWeight="bold" mt={4}>Course Schedule:</Text>
        <Box overflowX="auto" mt={4}>
            <Table variant="simple" size="sm" width="100%" border="1px solid black">
            <Thead bg="gray.100">
                <Tr>
                <Th textAlign="center" border="1px solid black">Date</Th>
                <Th textAlign="center" border="1px solid black">Start Time</Th>
                <Th textAlign="center" border="1px solid black">End Time</Th>
                </Tr>
            </Thead>
            <Tbody>
                {registrationData.course_dates.map((session, index) => (
                <Tr key={index}>
                    <Td textAlign="center" border="1px solid black">{session.date}</Td>
                    <Td textAlign="center" border="1px solid black">
                    {session.start_time}
                    </Td>
                    <Td textAlign="center" border="1px solid black">
                    {session.end_time}
                    </Td>
                </Tr>
                ))}
            </Tbody>
            </Table>
        </Box>
        </VStack>
        <HStack>
          <Text fontWeight="bold">h) Modes of Training:</Text>
          <Text>In-person classroom teaching</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">i) Minimum entry requirement(s):</Text>
          <Text>
              {
                  registrationData?.minimum_entry_requirements
                      ?
                      registrationData.minimum_entry_requirements
                      :
                      "N.A."
              }
          </Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">j) Prerequisites:</Text>
          <Text>
              {
                  registrationData?.prerequisities
                      ?
                      registrationData.prerequisities
                      :
                      "N.A."
              }
          </Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">k) Registered Training Provider:</Text>
          <Text>Sozcode</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">l) Organisation which awards/confers the qualitifcation:</Text>
          <Text>Sozcode</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">m) Funding Validity Period:</Text>
          <Text>
              {
                  registrationData?.funding_validity_period
                      ?
                      registrationData.funding_validity_period
                      :
                      "N.A."
              }
          </Text>
        </HStack>
      </VStack>
      <Divider my={6} />
      <VStack align="start" spacing={3} mt={4}>
        <Text fontSize="lg" fontWeight="bold">
            2. Course Fees
        </Text>
        <Box overflowX="auto" width="100%">
            <Table variant="simple" size="sm" width="100%" border="1px solid black" borderCollapse="collapse">
            <Thead>
                <Tr bg="gray.200">
                <Th textAlign="center" border="1px solid black">Fees Breakdown</Th>
                <Th textAlign="center" border="1px solid black">Total Payable ($)</Th>
                </Tr>
            </Thead>
            <Tbody>
  <Tr>
    <Td border="1px solid black">Course Fees (Before GST)</Td>
    <Td textAlign="center" border="1px solid black">{registrationData.price}</Td>
  </Tr>
  <Tr>
    <Td border="1px solid black">9% GST</Td>
    <Td textAlign="center" border="1px solid black">{(0.09 * registrationData.price)}</Td>
  </Tr>
  <Tr>
    <Td border="1px solid black">Less: 50% Baseline Funding</Td>
    <Td textAlign="center" border="1px solid black">
      {(registrationData.nationality === "Singapore Citizen" ||
        registrationData.nationality === "Singapore Permanent Resident" ||
        registrationData.nationality === "Long Term Visitor Pass+ (LTVP+)") 
        ? `-${(0.5 * registrationData.price)}` 
        : '-0'}
    </Td>
  </Tr>
  <Tr>
    <Td border="1px solid black">Less: 20% Mid-Career Enhanced Subsidy</Td>
    <Td textAlign="center" border="1px solid black">
      {(registrationData.nationality === "Singapore Citizen" ||
        registrationData.nationality === "Singapore Permanent Resident" ||
        registrationData.nationality === "Long Term Visitor Pass+ (LTVP+)") &&
        registrationData.birth_age >= 40 
        ? `-${(0.2 * registrationData.price)}` 
        : '-0'}
    </Td>
  </Tr>
  <Tr>
    <Td border="1px solid black"><strong>Nett Course Fees:</strong></Td>
    <Td textAlign="center" border="1px solid black">
      <strong>
        {(1.09*registrationData.price) -
          (((registrationData.nationality === "Singapore Citizen" ||
            registrationData.nationality === "Singapore Permanent Resident" ||
            registrationData.nationality === "Long Term Visitor Pass+ (LTVP+")) 
            ? (0.5 * registrationData.price) 
            : 0)- 
          ((registrationData.nationality === "Singapore Citizen" ||
            registrationData.nationality === "Singapore Permanent Resident" ||
            registrationData.nationality === "Long Term Visitor Pass+ (LTVP+)") &&
            (registrationData.birth_age >= 40 ) 
            ? (0.2 * registrationData.price) 
            : 0)}
      </strong>
    </Td>
  </Tr>
</Tbody>
            </Table>
        </Box>
        </VStack>
      <Divider my={6} />
      <Text fontSize="lg" fontWeight="bold">
        3. Attendance
      </Text>
        <VStack align="start" spacing={3} mt={4}>
        <Text>
        Trainees attending funded courses (SSG and IBF) must take their attendance digitally via the Singpass App for both physical classroom and synchronous e-learning courses.
        </Text>
        <Text>
        If a trainee does not meet the minimum attendance requirement (if any), trainees will not be eligible to meet assessment eligibility. Otherwise, trainees are advised to request a rescheduling of training and/or assessment sessions.
        </Text>
        </VStack>
      <Divider my={6} />
      <Text fontSize="lg" fontWeight="bold">
        4. Refund, Withdrawal and Rescheduling Policy
      </Text>
        <VStack align="start" spacing={3} mt={4}>
        <Text>
        Sozcode Pte Ltd strives to offer a fair and transparent refund policy. Please review the options below carefully:
        </Text>
        <Text>
        If a trainee does not meet the minimum attendance requirement (if any), trainees will not be eligible to meet assessment eligibility. Otherwise, trainees are advised to request a rescheduling of training and/or assessment sessions.
        </Text>
        <Box overflowX="auto" maxWidth="800px" margin="auto">
        <Table variant="simple" size="sm" width="100%">
            <Thead>
            <Tr>
                <Th fontWeight="bold" whiteSpace="normal" wordWrap="break-word" textAlign="center">
                Request Type
                </Th>
                <Th fontWeight="bold" whiteSpace="normal" wordWrap="break-word" textAlign="center">
                Timeframe
                </Th>
                <Th fontWeight="bold" whiteSpace="normal" wordWrap="break-word" textAlign="center">
                Options for Student
                </Th>
            </Tr>
            </Thead>
            <Tbody>
            <Tr>
                <Td rowSpan={3} whiteSpace="normal" wordWrap="break-word">
                Request for refund/withdrawal
                </Td>
                <Td whiteSpace="normal" wordWrap="break-word">
                Before course confirmation email is sent
                </Td>
                <Td whiteSpace="normal" wordWrap="break-word">
                Cancel with no cancellation charges
                </Td>
            </Tr>
            <Tr>
                <Td whiteSpace="normal" wordWrap="break-word">
                At least 10 business days before the course start date
                </Td>
                <Td whiteSpace="normal" wordWrap="break-word">
                Cancel with 50% course fees still payable
                </Td>
            </Tr>
            <Tr>
                <Td whiteSpace="normal" wordWrap="break-word">
                Less than 10 business days before the course start date
                </Td>
                <Td whiteSpace="normal" wordWrap="break-word">
                Cancel with 100% of the fees payable
                </Td>
            </Tr>
            <Tr>
                <Td rowSpan={2} whiteSpace="normal" wordWrap="break-word">
                Request for rescheduling
                </Td>
                <Td whiteSpace="normal" wordWrap="break-word">
                Recognised Absences with supporting documents:
                <UnorderedList>
                    <ListItem>Medical</ListItem>
                    <ListItem>Reservist</ListItem>
                    <ListItem>Court appearance</ListItem>
                    <ListItem>Bereavement of immediate family members</ListItem>
                </UnorderedList>
                </Td>
                <Td whiteSpace="normal" wordWrap="break-word">
                Re-scheduling for other course runs free-of-charge (subject to availability)
                </Td>
            </Tr>
            <Tr>
                <Td whiteSpace="normal" wordWrap="break-word">
                Rescheduling for reasons not included in Recognised Absences
                </Td>
                <Td whiteSpace="normal" wordWrap="break-word">
                Re-scheduling for other course runs at a one-time cost of $100 (subject to availability)
                </Td>
            </Tr>
            </Tbody>
        </Table>
        </Box> 
        </VStack> 
        <VStack align="start" spacing={3} mt={4}>
        <HStack>
          <Text fontWeight="bold">a)</Text>
          <Text>Sozcode Pte Ltd reserves the right to change or cancel the course due to unforeseen circumstances. A full refund of any course fees paid will be made to the Student in this circumstance.</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">b)</Text>
          <Text>A withdrawal request can be made via sending an email to courses@sozcode.com.</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">c)</Text>
          <Text> Refunds for credit card payments will be credited back to the original payment methods. Refunds for PayNow payment will be credited back through PaNow. All valid refunds will be credited back to the Student in an estimated 20 business days.</Text>
        </HStack>
        </VStack>
      <Divider my={6} />
      <Text fontSize="lg" fontWeight="bold">
       5. Appeal and Re-assessment Process
      </Text>
      <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
        <Image 
          src="https://cdn.prod.website-files.com/61795563005b7a7fcfbcab48/674e723e6f7ad240fd55a19c_Swim%20lane%20diagram%20(Course%20operations)%20-%20Frame%202.jpg" 
          alt="Appeal and Re-assessment Process Flowchart"
          maxWidth="100%"
          objectFit="contain"
        />
      </Box>
      <VStack align="start" spacing={3} mt={4}>
      <HStack>
      <Text>A non-refundable Appeal Fee of S$60 will apply to each appeal against the Not-Yet-Competent (NYC)' status awarded. The Academic and Examination Board members’ decision will be final.</Text>
      </HStack>
      <HStack>
      <Text>The Academic and Examination Board members’ decision will be final.</Text>
      </HStack>
      </VStack>
      <Divider my={6} />
      <Text>The parties hereby acknowledge and agree to the terms stated in this contract.</Text>
      <VStack align="start" spacing={4} mt={6}>
        <Text fontSize="lg" fontWeight="bold">
             Acknowledgment and Signatures
        </Text>
    <Box>
    <Text>SIGNED by Sozcode Pte Ltd.</Text>
    <Divider my={2} />
    <Box display="flex" justifyContent="left" alignItems="left" mt={4}>
        <Image 
          src="https://cdn.prod.website-files.com/61795563005b7a7fcfbcab48/674e723e8d3c5c06545cfe05_Brandon%20Signature.png" 
          alt="Sozcode Signature"
          maxWidth="30%"
          objectFit="contain"
        />
    </Box>
    <Text>__________________________</Text>
    <Text>Authorised Signatory</Text>
    <Text>Name: Brandon Ong</Text>
    <Text>Date: {new Date().toLocaleDateString('en-GB')}</Text>
    <Divider my={6} />
    </Box>
</VStack>
    <Text>SIGNED by the Student</Text>
    <Divider my={2} />
      <Box>
        <SignaturePad
          ref={sigPad}
          canvasProps={{
            className: "signatureCanvas",
            height: 300,
            width: 400,
          }}
        />
        <Button mt="1" mb="8" maxW={200} minW={100}  w="20%" size={"xs"} variant={"outline"} onClick={clearSignature}>
          Clear Signature
        </Button>
      </Box>
      <Text>__________________________</Text>
      <Text>Name of Student: {registrationData.participant_name}</Text>
    <Text>Date: {new Date().toLocaleDateString('en-GB')}</Text>
    <Divider my={4} />
    <Button
          mt="4" mb="8" bg={"#F17946"} maxW={400} minW={200} w="50%" size={"lg"}
          onClick={saveSignatureAndProceed}
          isLoading={submitting} 
        >
          Save Signature & Proceed to Checkout
        </Button>
    </Box>
    
  );
};

//Link to trigger signed student contract, after payment page is done
// await fetch("https://eolfpfl5jdmf3k5.m.pipedream.net", {
//     method: "POST", 
//     headers: {
//         'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//         registration_id: registrationData.id,
//     })
// })
// .then(response => {
//     if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     return response.json();
// })
// .then(data => {
//     console.log("Success:", data);
// })
// .catch(error => {
//     console.error("Error:", error);
// });