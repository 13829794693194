import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
  Box,
  Text,
  useToast,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Spinner,
  Image, Heading
} from "@chakra-ui/react";
import { motion } from "framer-motion";

export const SuccessRegistration = () => {
    const { registrationID } = useParams();
    const [registrationData, setRegistrationData] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchRegistration = async () => {
          setLoading(true);
          try {
            const { data, error } = await supabase
              .from("full_registration_details_with_enrollment")
              .select(
                "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality,course_dates" //course_dates
              )
              .gte("start_date", new Date().toLocaleDateString('fr-CA'))
              .eq("id", registrationID)
              .single();
    
            if (error) throw error;
    
            setRegistrationData(data);
          } catch (error) {
            console.error("Error fetching registration:", error);
            toast({
              title: "Error",
              description: "Could not load registration details.",
              status: "error",
              isClosable: true,
              position: "top",
            });
          } finally {
            setLoading(false);
          }
        };
    
        fetchRegistration();
      }, [registrationID, toast]);
    return (
    loading ? (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh" // Full viewport height
        width="100%" // Full width
      >
        <Spinner size="xl" color="teal.500" />
      </Box>):(
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      color="black"
      textAlign="center"
      p={8}
      overflow="auto" // For scrolling if content exceeds the viewport height
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <VStack spacing={8} align="center" w="full">
          <motion.div
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
          >
            <Image
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAMFBMVEVHcEwOFzUOFzUOFzUOFzUOFjUOFzUPGDYPFzYOFzUOFzUOFzUOFzUOFzUOFzUOFzV6hvApAAAAD3RSTlMARvPjmlTRDR2xcIUyYL/4uzujAAAAsElEQVQokZWSSRbEIAhEEWczcP/btmJsJeoitQv/QUEZgEnhCnPxkSUiu2FnZoQbiAVuWhMz0kuoKyQ1Oh0HT/IPI9eZ48IJoBqkaVbgO2Tn1Srx3xl9g7HPqp7Gd8d7MLKIh1jfNLa6LjSY+NMbvOdYeMMQ62pdqhxTvM57aVAWVG7rblsWMj1OAmmQEV0kNb5neDHxJ6gXHF8TQDLhmA/BPYMhYFQTy0pRa2eW6LN+wJAQWr/Uv44AAAAASUVORK5CYII="
              alt="Logo"
              boxSize="100px"
              mb={4}
            />
          </motion.div>
          <motion.div
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
          >
            <Heading fontSize="6xl" fontWeight="extrabold" >
              🎉 Success! 🎉
            </Heading>
          </motion.div>
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.3, duration: 0.8, ease: "easeOut" }}
          >
            <Text fontSize="2xl" fontWeight="bold">
              Thank you for registering, {registrationData.participant_name}!
            </Text>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
          >
            <Text fontSize="xl" fontWeight="medium">
              You will receive an enrollment pending email sent to {registrationData.participant_email} soon.
            </Text>
          </motion.div>
        </VStack>
      </motion.div>
    </Box>)
  );
};

export default SuccessRegistration;