import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {Box, Button, Center, FormControl, FormLabel, Heading, Badge,Select, Stack, Text, HStack, Divider, Card,Link, useToast} from "@chakra-ui/react";
import {CheckCircleIcon} from "@chakra-ui/icons";
import { FiFile, FiLink, FiArrowRight, FiUpload } from "react-icons/fi";
import {saveAs} from "file-saver";


export const EachAssessment = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const toast = useToast()
    const participantName = "ABC"
    const {assessmentID} = useParams();
    const [selectedFile, setSelectedFile] = useState(null);
    const [courseMaterials, setCourseMaterials] = useState([])
    console.log("Assessment ID from URL:", assessmentID); //Need to go app to add :assessmentID to extract the right part of url
    const getAllCourseMaterials = async () => {
        const { data, error } = await supabase
                    .from("full_assessment_details")
                    .select("*")
                    .eq("assessment_id", assessmentID)
                    .single();
        // const data =
        //     {
        //         "name": "Python and GPT4 (ChatGPT) For Beginners",
        //         "start_date": "2024-11-13",
        //         "end_date": "2024-11-14",
        //         "assessments": [
        //             {
        //                 "assessment_name": "Assessment 1: Case Study - Manipulate Payroll CSV with Pandas",
        //                 "items": [
        //                     {"type": "text", "item_name": "Please download the csv file 'Payroll Pandas.csv' below. Your objective is to manipulate the file below to achieve the table format shown in 'Desired Output.jpeg'"},
        //                     {"type": "file", "item_name": "Payroll Pandas.csv"},
        //                     {"type": "file", "item_name": "Desired Output.jpeg"},
        //                     {"type": "submission", "item_name": "Please upload your ipynb file here"},
        //                 ]
        //             },
        //             {
        //                 "assessment_name": "Capstone Project: Create your own Intelligent Workflow With GenAI",
        //                 "items": [
        //                     {"type": "text", "item_name": "Please download the csv file 'Payroll Pandas.csv' below. Your objective is to manipulate the file below to achieve the table format shown in 'Desired Output.jpeg'"},
        //                     {"type": "submission", "item_name": "Please upload your ipynb file here"},
        //                 ]
        //             },
        //         ]
        //     }
        if (data) {
            console.log(data);
            setCourseMaterials(data);
        }
        if (error) {
            console.log(error);
        }
    };

    console.log(courseMaterials.code)


    // File download function
    // const downloadFile = async (filePath) => {
    //     try {
    //         const { data, error } = await supabase.storage.from("Assessment Files").download(filePath);
    //         if (error) throw error;
    //         const fileName = filePath.split("/").pop();
    //         saveAs(data, fileName);
    //     } catch (error) {
    //         console.error("Error downloading file:", error);
    //     }
    // };

    const downloadFile = async (filePath, bucketName="Course Files") => { 
        setIsDownloading(true)
        try {
            const { data, error } = await supabase.storage.from(bucketName).download(filePath);
            if (error) throw error;
    
            const fileName = filePath.split("/").pop();
            saveAs(data, fileName);
            
            // Insert a record into the "activity" table upon successful download
            const { error: activityError } = await supabase
            .from("activity")
            .insert({
                created_at: new Date().toISOString(),
                registration_id:courseMaterials.id,
                action: "Download",
                resource: fileName,
            });

        if (activityError) {
            console.error("Error logging download activity:", activityError);
        }
        } catch (error) {
            console.error(`Error downloading file from ${bucketName}:`, error);
        } finally {
            setIsDownloading(false);
        }
    };

     useEffect(() => {
            const listFiles = async () => {
              const folderName = courseMaterials.code; // Specify your folder name
              const { data, error } = await supabase.storage.from("Course Files").list(folderName);
          
              if (data) {
                const filesWithPath = data.map(file => ({
                  name: file.name,
                  path: `${folderName}/${file.name}`, // Construct the full file path
                }));
                console.log("Files in the folder with paths:", filesWithPath);
              } else {
                console.error("Error listing files:", error);
              }
            };
            listFiles();
          }, []);
    

    const handleFileUpload = async (file) => {
        setIsSubmitting(true)
        if (!file.name.endsWith(".ipynb")) {
            setIsSubmitting(false)
            toast({
                title: "Invalid file type",
                description: "Only .ipynb files are allowed.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const path = `${courseMaterials.id}/student/${file.name}`; //courseMaterials.name to be replaced by assignment name
        const { data, error } = await supabase.storage.from("Assessment Files").upload(path, file, {
            upsert: true,
        });

        if (error) {
            setIsSubmitting(false)
            toast({
                title: "Upload failed",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } else {
            // Insert a record into the "activity" table upon successful upload
            const { error: activityError } = await supabase
            .from("activity")
            .insert({
                created_at: new Date().toISOString(),
                registration_id:courseMaterials.id,
                action: "Upload",
                resource: file.name,
            });
            if (activityError) {
            console.error("Error logging upload activity:", activityError);
            }

            // Update the assessments table with `submitted_on` and `uploaded_file_name`
            const { data: updateData, error: updateError } = await supabase
            .from("assessments")
            .update({
                submitted_on: new Date().toISOString(), // Current timestamp
                uploaded_file_name: file.name, // File name
            })
            .eq("assessment_id", assessmentID);

            console.log("update response:", updateData, updateError);
            
            if (updateError) {
                setIsSubmitting(false)
                toast({
                    title: "Update failed",
                    description: updateError.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                setIsSubmitting(false)
                toast({
                    title: "Upload successful",
                    description: `Your assessment has been uploaded.`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
            });
            getAllCourseMaterials();

             // Clear the file input
             setSelectedFile(null); // Clear state
             document.getElementById("file-upload").value = ""; // Clear file input field
            }
        }
    };

    useEffect(() => {
        getAllCourseMaterials()
    }, [])

    return (
        <Box>
            <Heading size={"xs"} fontWeight="medium">
                Assessment For {courseMaterials.name} ({courseMaterials.start_date} - {courseMaterials.end_date})
            </Heading>
            {courseMaterials?.assessment_items?.map(each =>   //map function is only for array
                <Box>
                    <HStack mt={10} mb={0.5}>
                        <Heading size={"2xs"} fontWeight="medium">
                            {each.assessment_name}
                        </Heading>
                        <Badge mb={1} colorScheme={courseMaterials.submitted_on ? "green" : "red"}>
                                        {courseMaterials.submitted_on ? "Submitted" : `Not Complete - Deadline: ${courseMaterials.assessment_deadline.split("T")[0]}`}
                        </Badge>
                    </HStack>
                    <Divider mt={2} mb={4} borderColor={"#0E1735"}/>
                    {
                        each?.items?.map(item =>
                                item.type === "text"
                                    ?
                                    <Text mb={5} fontWeight={300}>{item.item_name}</Text>
                                    :
                                    item.type === "submission"
                                        ?
                                        <Card p={5} borderRadius={5} mb={5}>
                                            <Stack>
                                                <Text>{item.item_name}</Text>
                                                <Box mt={5} borderColor={"black"}>
                                                    <input
                                                        id="file-upload"
                                                        type="file"
                                                        accept=".ipynb" // Restrict file type
                                                        onChange={(e) => setSelectedFile(e.target.files[0])} // Set selected file
                                                        style={{ marginBottom: "10px" }}
                                                    />
                                                    <Button isLoading={isSubmitting} 
                                                    leftIcon={<FiUpload />}
                                                    onClick={() => handleFileUpload(selectedFile)}
                                                    isDisabled={!selectedFile}>
                                                    Upload
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        </Card>
                                        :
                                        <Card p={5} borderRadius={5} mb={5}>
                                            <HStack>
                                                {item.type === "file" ? <FiFile/> : <FiLink/>}
                                                <Link
                                                    as="span"
                                                    onClick={() => downloadFile(`${courseMaterials.code}/${item.item_name}`)}//console.log(`${courseMaterials.code}/${item.item_name}`)} 
                                                    color="blue.500"
                                                    textDecoration="underline"
                                                    cursor="pointer"
                                                >
                                                    {item.item_name}
                                                </Link>
                                                
                                            </HStack>
                                        </Card>
                        )
                    }
                </Box>
            )}
            {courseMaterials.submitted_on && (
                <Card p={5} borderRadius={5} mb={5} bg="green.50">
                    <Heading size="sm" mb={2} color="green.700">
                        Your Submission
                    </Heading>
                    <Text mb={2}>
                        File Name: <strong>{courseMaterials.uploaded_file_name}</strong>
                    </Text>
                    <Button isLoading={isDownloading} 
                        leftIcon={<FiFile />}
                        colorScheme="blue"
                        onClick={() => downloadFile(`${courseMaterials.id}/student/${courseMaterials.uploaded_file_name}`,"Assessment Files")} 
                    >
                        Download Your Submission
                    </Button>
                    <Text mt={2} fontSize="sm" color="gray.500">
                        Submitted on: {new Intl.DateTimeFormat("en-CA", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                        }).format(new Date(courseMaterials.submitted_on))}
                    </Text>
                </Card>
            )}
            
        </Box>
        
    )
}
