import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
    Box,
    Center,
    Heading,
    Stack,
    Text,
    Card,
    CardBody,
    CardHeader,
    useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import { Spinner } from '@chakra-ui/react'

export const AttendanceSelection = () => {
    const [coursesToday, setCoursesToday] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Fetch courses happening today
    const fetchCoursesToday = async () => {
        setIsLoading(true);
        const today = new Date().toISOString().split("T")[0];
        const { data, error } = await supabase
            .from("course_run_details")
            .select("id, name, start_date, end_date,attendance_id")
            .gte("end_date", today)
            //.lte("end_date", today)
            .order("start_date", { ascending: true })
            .limit(5); // Fetch up to 5 courses for presentation

        if (error) {
            console.error("Error fetching courses:", error);
            setCoursesToday()
        } else {
            console.log(data);
            setCoursesToday(data);
            setIsLoading(false);
            if (data.length === 1) {
                // If only one course, redirect to its attendance page
                navigate(`/attendance/${data[0].attendance_id}`);
            }
        }
    };

    // Fetch courses when component mounts
    useEffect(() => {
        fetchCoursesToday();
    }, []);

    const cardBg = useColorModeValue("white", "gray.800");
    const cardHoverBg = useColorModeValue("gray.100", "gray.700");
    const cardBorder = useColorModeValue("1px solid #E2E8F0", "1px solid #4A5568");

    return (
            isLoading
            ?
            <Spinner/>
            :
            <Box w={"100%"} display="flex" justifyContent="center" alignItems="center" bg={"#F4EEEA"}>
                <Center maxW={"90%"} width={800} pt={3} pb={20}>
                    <Stack width="100%">
                        <Heading size={"md"} mb={8}>
                            Select Your Course for Attendance
                        </Heading>
                        {coursesToday.length > 0 ? (
                            <Stack spacing="6">
                                {coursesToday.map((course) => (
                                    <Card
                                        key={course.id}
                                        bg={cardBg}
                                        border={cardBorder}
                                        borderRadius="md"
                                        boxShadow="sm"
                                        transition="all 0.3s"
                                        _hover={{
                                            bg: cardHoverBg,
                                            boxShadow: "lg",
                                            transform: "translateY(-4px)",
                                        }}
                                        onClick={() => navigate(`/attendance/${course.attendance_id}`)}
                                        cursor="pointer"
                                    >
                                        <CardHeader>
                                            <Heading size="xs">
                                                {course.name}
                                            </Heading>
                                        </CardHeader>
                                        <CardBody>
                                            <Text size={"sm"} color={"gray.600"}>
                                                Start date: {moment(course.start_date).format("DD MMM yyyy")}

                                            </Text>
                                            <Text size={"sm"} color={"gray.600"}>
                                                End date: {moment(course.end_date).format("DD MMM yyyy")}
                                            </Text>

                                        </CardBody>
                                    </Card>
                                ))}
                            </Stack>
                        ) : (
                            <Text>No courses scheduled for today.</Text>
                        )}
                    </Stack>
                </Center>
            </Box>
    );
};