// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { supabase } from "./utils/supabaseClient";
// import {
//   Box,
//   Text,
//   useToast,
//   VStack,
//   Table,
//   Thead,
//   Tr,
//   Th,
//   Tbody,
//   Td,
//   Button,
//   Spinner,
// } from "@chakra-ui/react";


// export const SuccessAcknowledgement = () => {
//   const { assessmentID } = useParams();
//   const [assessmentData, setAssessmentData] = useState(null);
//   const toast = useToast();
//   console.log("Assessment ID from URL:", assessmentID);
//   const [loading, setLoading] = useState(true);
//   const [submitting, setSubmitting] = useState(false);

//   useEffect(() => {
//     const fetchRegistration = async () => {
//       setLoading(true);
//       try {
//         const { data, error } = await supabase
//           .from("full_assessment_details")
//           .select(
//             "assessment_id, assessment_name, participant_name, is_acknowledged,assessment_results, submitted_on, marked_on, remarks"
//           )
//           .eq("assessment_id", assessmentID)
//           .single(); // Ensures only a single row is fetched
//           console.log(data)

//         if (error) throw error;

//         setAssessmentData(data);
//       } catch (error) {
//         console.error("Error fetching assessment:", error);
//         toast({
//           title: "Error",
//           description: "Could not load assessment details.",
//           status: "error",
//           isClosable: true,
//           position: "top",
//         });
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchRegistration();
//   }, [assessmentID, toast]);

//   const saveAcknowledgement = async () => {
//     setSubmitting(true);
//     try {
//       const { error } = await supabase
//         .from("assessments")
//         .update({ is_acknowledged: true })
//         .eq("assessment_id", assessmentID);

//       if (error) throw error;

//       toast({
//         title: "Acknowledged",
//         description: "The results have been successfully acknowledged. Thank you!",
//         status: "success",
//         duration: 3000,
//         isClosable: true,
//         position: "top",
//       });

//       // Update local state to reflect the change
//       setAssessmentData((prevData) => ({
//         ...prevData,
//         is_acknowledged: true,
//       }));
//     } catch (error) {
//       console.error("Error updating assessment:", error);
//       toast({
//         title: "Error",
//         description: "Could not acknowledge the results.",
//         status: "error",
//         duration: 3000,
//         isClosable: true,
//         position: "top",
//       });
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   return loading ? (
//     <Box
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       minHeight="100vh"
//       width="100%"
//     >
//       <Spinner size="xl" color="teal.500" />
//     </Box>
//   ) : assessmentData ? (
//     <Box
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       minHeight="100vh"
//       color="black"
//       textAlign="center"
//       p={8}
//     >
//       <Box>
//         <Table variant="simple" size="md">
//           <Tbody>
//             <Tr>
//               <Td><b>Participant Name</b></Td>
//               <Td>{assessmentData.participant_name || "N/A"}</Td>
//             </Tr>
//             <Tr>
//               <Td><b>Assessment Name</b></Td>
//               <Td>{assessmentData.assessment_name || "N/A"}</Td>
//             </Tr>
//             <Tr>
//               <Td><b>Assessment Results</b></Td>
//               <Td>{assessmentData.assessment_results || "N/A"}</Td>
//             </Tr>
//             <Tr>
//               <Td><b>Assessment Submission Date</b></Td>
//               <Td>{assessmentData.submitted_on?.split("T")[0] || "N/A"}</Td>
//             </Tr>
//             <Tr>
//               <Td><b>Assessment Marked On</b></Td>
//               <Td>{assessmentData.marked_on?.split("T")[0] || "N/A"}</Td>
//             </Tr>
//             <Tr>
//               <Td><b>Remarks</b></Td>
//               <Td>{assessmentData.remarks || "N/A"}</Td>
//             </Tr>
//           </Tbody>
//         </Table>
//         <Button
//           mt="4"
//           mb="8"
//           bg={assessmentData?.is_acknowledged ? "green.500" : "#F17946"}
//           maxW={400}
//           minW={200}
//           w="50%"
//           size={"lg"}
//           color="white"
//           _hover={{ bg: assessmentData?.is_acknowledged ? "green.600" : "#F06530" }}
//           isDisabled={assessmentData?.is_acknowledged} // Disable the button after acknowledgment
//           onClick={!assessmentData?.is_acknowledged ? saveAcknowledgement : undefined} // Prevent further clicks
//         >
//           {assessmentData?.is_acknowledged ? "Results Acknowledged" : "Acknowledge Results"}
//         </Button>
//       </Box>
//     </Box>
//   ) : (
//     <Box>
//       <Text>No assessment data available.</Text>
//     </Box>
//   );
// };

// export default SuccessAcknowledgement;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
  Box,
  Text,
  VStack,
  Table,
  Tbody,
  Tr,
  Td,
  Spinner,
  Button,
  Heading,
  Icon,
  Badge,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { CheckCircleIcon } from "@chakra-ui/icons";

export const SuccessAcknowledgement = () => {
  const { assessmentID } = useParams();
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchAssessment = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from("full_assessment_details")
          .select(
            "assessment_id, assessment_name, participant_name, is_acknowledged, assessment_results, submitted_on, marked_on, remarks"
          )
          .eq("assessment_id", assessmentID)
          .single();

        if (error) throw error;

        setAssessmentData(data);
      } catch (error) {
        console.error("Error fetching assessment:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessment();
  }, [assessmentID]);

  const saveAcknowledgement = async () => {
    setSubmitting(true);
    try {
      const { error } = await supabase
        .from("assessments")
        .update({ is_acknowledged: true })
        .eq("assessment_id", assessmentID);

      if (error) throw error;

      setAssessmentData((prevData) => ({
        ...prevData,
        is_acknowledged: true,
      }));
    } catch (error) {
      console.error("Error updating assessment.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Spinner size="xl" color="teal.500" />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      p={8}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          bg="white"
          shadow="xl"
          borderRadius="lg"
          p={8}
          maxW="600px"
          w="full"
        >
          <VStack spacing={4} align="center" mb={6}>
            <Heading size="lg">
              {assessmentData?.is_acknowledged ? (
                <>
                  <Icon as={CheckCircleIcon} color="green.500" boxSize={6} />
                  <Text as="span" ml={2}>
                    Results Acknowledged
                  </Text>
                </>
              ) : (
                "Assessment Results"
              )}
            </Heading>
            <Badge
              colorScheme={assessmentData?.is_acknowledged ? "green" : "blue"}
              fontSize="lg"
              px={4}
              py={1}
              borderRadius="full"
            >
              {assessmentData?.is_acknowledged
                ? "Acknowledged"
                : "Pending Acknowledgement"}
            </Badge>
          </VStack>

          <Table variant="simple" size="md">
            <Tbody>
              <Tr>
                <Td fontWeight="bold">Participant Name</Td>
                <Td>{assessmentData?.participant_name || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Assessment Name</Td>
                <Td>{assessmentData?.assessment_name || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Results</Td>
                <Td>{assessmentData?.assessment_results || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Submitted On</Td>
                <Td>{assessmentData?.submitted_on?.split("T")[0] || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Marked On</Td>
                <Td>{assessmentData?.marked_on?.split("T")[0] || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Remarks</Td>
                <Td>{assessmentData?.remarks || "N/A"}</Td>
              </Tr>
            </Tbody>
          </Table>

          <Button
            mt={6}
            w="full"
            bg={assessmentData?.is_acknowledged ? "green.500" : "teal.500"}
            color="white"
            _hover={{
              bg: assessmentData?.is_acknowledged ? "green.600" : "teal.600",
            }}
            isDisabled={assessmentData?.is_acknowledged || submitting}
            onClick={saveAcknowledgement}
          >
            {assessmentData?.is_acknowledged
              ? "Thank you!"
              : "Acknowledge Results"}
          </Button>
        </Box>
      </motion.div>
    </Box>
  );
};

export default SuccessAcknowledgement;