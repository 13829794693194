import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {Box, Button, Center, FormControl, FormLabel, Heading, Select, Stack, Text, HStack, Divider,Link, Card} from "@chakra-ui/react";
import {CheckCircleIcon} from "@chakra-ui/icons";
import { FiFile, FiLink, FiArrowRight } from "react-icons/fi";
import {saveAs} from "file-saver";


export const EachCourse = () => {
    const [courseMaterials, setCourseMaterials] = useState(null);
    const {courseRunId} = useParams();
    const getAllCourseMaterials = async () => {
        const {data, error } = await supabase
            .from("full_registration_details_with_enrollment")
            .select("id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality, course_dates,code,course_resources")
            .eq("id", courseRunId) 
            .single();

        if (data) {
            console.log(data);
            setCourseMaterials(data);
        }
        if (error) {
            console.log(error);
        }
    };

    const downloadFile = async (filePath) => {
        try {
            const { data, error } = await supabase.storage.from("Course Files").download(filePath);
            if (error) {
                console.error("Error downloading file:", error);
                return;
            }
            const fileName = filePath.split("/").pop();
            saveAs(data, fileName); // Use file-saver to trigger download
        } catch (error) {
            console.error("Unexpected error downloading file:", error);
        }
    };

    useEffect(() => {
        getAllCourseMaterials()
    }, [])

    useEffect(() => {
        const listFiles = async () => {
          const folderName = "SZCSSG01"; // Specify your folder name
          const { data, error } = await supabase.storage.from("Course Files").list(folderName);
      
          if (data) {
            const filesWithPath = data.map(file => ({
              name: file.name,
              path: `${folderName}/${file.name}`, // Construct the full file path
            }));
            console.log("Files in the folder with paths:", filesWithPath);
          } else {
            console.error("Error listing files:", error);
          }
        };
        listFiles();
      }, []);

    // const listBuckets = async () => {
    //     const { data, error } = await supabase.storage.listBuckets();
    //     if (error) {
    //         console.error('Error listing buckets:', error);
    //     } else {
    //         console.log('Available buckets:', data);
    //     }
    // };
    
    // listBuckets();



    if (!courseMaterials) {
        return <Text>Loading...</Text>; // Show a loading message while data is fetched
      }
    
      if (!courseMaterials.course_resources) {
        return <Text>No resources available for this course.</Text>; // Handle missing resources
      }
    return (
        <Box>
    <Heading size={"xs"} fontWeight="medium">
        Resources For {courseMaterials.course_resources.name} ({courseMaterials.start_date} - {courseMaterials.end_date})
    </Heading>
    {courseMaterials?.course_resources.resources?.map((each, resourceIndex) => (
        <Box key={`resource-${resourceIndex}`}>
        <HStack mt={10} mb={0.5}>
          <Heading size="2xs" fontWeight="medium">
            {each.resource_name}
          </Heading>
        </HStack>
        <Divider mt={2} mb={4} borderColor={"#0E1735"} />
        {each?.items?.map((item, itemIndex) => {
          if (item.type === "file") {
            // For file types, use Supabase download
            return (
              <Card key={`file-${resourceIndex}-${itemIndex}`} p={5} borderRadius={5} mb={5}>
                <HStack>
                  <FiFile />
                  <Link
                    as="span"
                    onClick={() => downloadFile(item.item_name)}
                    color="blue.500"
                    textDecoration="underline"
                    cursor="pointer"
                  >
                    Download {item.item_name.split("/").pop()}
                  </Link>
                </HStack>
              </Card>
            );
          } else if (item.type === "link") {
            // For external links
            return (
              <Card key={`link-${resourceIndex}-${itemIndex}`} p={5} borderRadius={5} mb={5}>
                <HStack>
                  <FiLink />
                  <Link
                    href={item.item_name}
                    isExternal
                    color="blue.500"
                    textDecoration="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.item_name}
                  </Link>
                </HStack>
              </Card>
            );
          } else {
            // For text types
            return (
              <Text key={`text-${resourceIndex}-${itemIndex}`} mb={5} fontWeight={300}>
                {item.item_name}
              </Text>
            );
          }
        })}
      </Box>
    ))}
  </Box>
);
};