import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {extendTheme} from "@chakra-ui/react";
import {theme} from "@chakra-ui/pro-theme";
import {ChakraProvider, Text, Heading} from "@chakra-ui/react";
import Fonts from "./Fonts";
import AuthProvider from "./providers/AuthProvider";

const proTheme = extendTheme(theme);
const extension = {
    styles: {
        global: {
            body: {
                color: '#0E1735', // Replace 'yourCustomColor' with the desired color code or variable
            },
        },
    },
    colors: {...proTheme.colors, brand: proTheme.colors.blue},
    fonts: {
        heading: `Inter, sans-serif`,
        body: `Inter, sans-serif`,
    },
};
const myTheme = extendTheme(extension, proTheme);


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <ChakraProvider theme={myTheme}>
            <AuthProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </AuthProvider>
        </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
